import React, { useState } from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import './Contact.css'
import Footer from "../Footer";

export default function () {
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: '',
        topic: '' // Added a new field for the topic selection
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        if (Object.values(formData).some(x => x === '')) {
            alert('Please enter all credentials');
        } else {
            // Fetch call to send formData
            console.log("Sending Data:", formData);
            const apiUrl = 'https://api.emailjs.com/api/v1.0/email/send'; // Replace with the actual API endpoint
            const credentials = { service_id: 'service_10ve1xb', template_id: 'template_1uom0kr', user_id: '8mJgKdWP4KMilGRz_', template_params: formData };
            console.log("credentials" + JSON.stringify(credentials))
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            })
                .then(response => {
                    if (response.ok) {
                        alert('message sent successfully');
                        return response.json();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(responseData => {
                    console.log('Response from server:', responseData);
                    // Handle the response data here
                })
                .catch(error => {
                    console.error('There was a problem with the POST request:', error);
                });

            const sendBtn = document.getElementById("sendBtn");
            sendBtn.setAttribute('disabled', 'true');
        }
    };

    return (
        <main id="Contact Us" className="flex-col md:flex-row w-[100%] h-[100%] overflow-hidden">
            <img 
                    src='/images/HeroImage.png' 
                    alt='vg image'
                    className='w-full absolute h-[90%]'
                />
                            {/* <img 
                    src='/images/HeroImage.png' 
                    alt='vg image'
                    className='w-full absolute h-[100%]'
                /> */}

                <img 
                    src='/images/HeroImage_pt.png' 
                    className='w-full h-[100%] absolute sm:hidden'
                />
            <div className="relative contact_form md:mt-[70px] mt-[30px] md:ml-[50px] m-[20px] mb-[60px] flex-grow flex justify-center">
                <div className="w-full">
                    <h1 className="text-center text-[50px] font-bold">Contact Us</h1>
                    <span className="md:text-[25px] text-[16px] text-center font-normal text-black w-max mb-[50px]">Helping Clients Reach Their Goals</span>

                    <form className="w-full flex flex-col gap-y-2 max-w-[800px] m-auto mt-5" onSubmit={handleSubmit}>
                        <div>
                            <label className="text-[14px] font-medium block text-left">Your Name</label>
                            <input
                                type="text"
                                name="name"
                                className=" w-full h-[40px]  text-black px-3 text-[14px] focus:outline-none rounded-[3px] border-[1px] border-black"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label className="text-[14px] font-medium block text-left">Contact Number</label>
                            <input
                                type="text"
                                name="number"
                                className=" w-full h-[40px]  text-black px-3 text-[14px] focus:outline-none rounded-[3px] border-[1px] border-black"
                                value={formData.number}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label className="text-[14px] font-medium block text-left">Email Address</label>
                            <input
                                type="email"
                                name="email"
                                className=" w-full h-[40px]  text-black px-3 text-[14px] focus:outline-none rounded-[3px] border-[1px] border-black"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label className="text-[14px] font-medium block text-left">Select Topic</label>
                            <select
                                name="topic"
                                className=" w-full h-[40px]  text-black px-3 text-[14px] focus:outline-none rounded-[3px] border-[1px] border-black"
                                value={formData.topic}
                                onChange={handleChange}
                            >
                                <option value="">Select a Topic</option>
                                <option value="Websites">Websites</option>
                                <option value="Software">Software</option>
                                <option value="Apps">Apps</option>
                                <option value="Digital Marketing">Digital Marketing</option>
                                <option value="Graphics Design">Graphics Design</option>
                                <option value="AI">AI</option>
                            </select>
                        </div>
                        <div>
                            <label className="text-[14px] font-medium block text-left">Message</label>
                            <textarea
                                className=" w-full h-[150px] resize-none  text-black px-3 pt-2 focus:outline-none rounded-[10px] border-[1px] border-black"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <button
                            id="sendBtn"
                            className="z-50 sm:w-full w-full m-auto md:max-w-[300px] flex justify-center mt-5 transition duration-300 bg-[#3288C3] text-[18px] text-white rounded-[50px] py-4 md:px-[100px] font-normal hover:bg-[#1E5B84] hover:cursor-pointer"
                        >
                            Submit <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" />
                        </button>
                    </form>
                </div>
            </div>
            <Footer className='z-10'/>
        </main>
    );
}
