import React from "react";
import { RxArrowTopRight } from "react-icons/rx";

export default function Projects() {
    return (
        <main id="About" className="w-full h-max py-[50px] lg:px-[100px] px-[20px] flex flex-col">
            
            <span className="md:text-[60px] ml-5 text-[30px] font-bold text-black text-center w-max md:float-left">
                Project Successes
            </span>
            <span className="md:text-[32px] ml-5 text-[18px] translate-y-[-10px] font-normal text-black w-max md:float-left mb-[20px]">
                Helping Clients Reach their Goals
            </span>

            <ul className="flex w-[100%] flex-col lg:flex-row lg:gap-[0] gap-[50px] text-left place-content-between">
                <li className="lg:w-1/3 w-[95%] mx-[10px] h-[100%] bg-white shadow-xl rounded-[30px] relative">
                    <div className="flex gap-[-50px] items-end px-10 relative z-10">
                        <img
                            src='/images/websiteScreenshotAj.png'
                            alt='vg image'
                            className='w-[80%] h-[200px] object-cover z-10 rounded-t'
                            style={{
                                objectPosition: "top center",
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.6)',
                                marginRight: '-45%'
                            }}
                        />
                        <img
                            src='/images/websiteScreenshotAj.png'
                            alt='vg image'
                            className='w-[80%] h-[180px] object-cover z-5 rounded-t'
                            style={{ objectPosition: "center" }}
                        />
                    </div>
                    <div className="proj-desc px-10 py-5 bg-white z-20 relative h-max" style={{ zIndex: 20 }}>
                        <h2 className="text-[25px] sm:text-[30px] font-bold">Football Website</h2>
                        <p className="text-[17px]  sm:text-[20px] font-normal mb-5">AJ Sport Academy nurtures young athletes with expert coaching and professional pathways.</p>
                        <a target="_blank" href="https://ajsportacademy.co.uk" className="border-b-4 font-medium text-[21px] flex w-max items-center">
                            Visit Site
                            <RxArrowTopRight className="text-[30px] font-bold" />
                        </a>
                    </div>
                </li>

                <li className="lg:w-1/3 w-[95%] mx-[10px] h-[100%] bg-white shadow-xl rounded-[30px] relative h-inherit">
                    <div className="flex gap-[-50px] items-end px-10 relative z-10">
                        <img
                            src='/images/websiteScreenshotRimas.png'
                            alt='vg image'
                            className='w-[80%] h-[200px] object-cover z-10 rounded-t'
                            style={{
                                objectPosition: "top center",
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.6)',
                                marginRight: '-45%'
                            }}
                        />
                        <img
                            src='/images/websiteScreenshotRimas.png'
                            alt='vg image'
                            className='w-[80%] h-[180px] object-cover z-5 rounded-t'
                            style={{ objectPosition: "center" }}
                        />
                    </div>
                    <div className="proj-desc px-10 py-5 bg-white z-20 relative h-[100%]" style={{ zIndex: 20 }}>
                        <h2 className="text-[25px] sm:text-[30px] font-bold">Clothing E-commerce</h2>
                        <p className="text-[17px]  sm:text-[20px] font-normal mb-5">Rimas Store offers premium cultural clothing and secure shopping with tradition and style.</p>
                        <a target="_blank" href="https://rimastores.com" className="border-b-4 font-medium text-[21px] flex w-max items-center">
                            Visit Site
                            <RxArrowTopRight className="text-[30px] font-bold" />
                        </a>
                    </div>
                </li>

                <li className="lg:w-1/3 w-[95%] mx-[10px] h-[100%] bg-white shadow-xl rounded-[30px] relative">
                    <div className="flex gap-[-50px] items-end px-10 relative z-10">
                        <img
                            src='/images/websiteScreenshotNile.png'
                            alt='vg image'
                            className='w-[80%] h-[200px] object-cover z-10 rounded-t'
                            style={{
                                objectPosition: "top center",
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.6)',
                                marginRight: '-45%'
                            }}
                        />
                        <img
                            src='/images/websiteScreenshotNile.png'
                            alt='vg image'
                            className='w-[80%] h-[180px] object-cover z-5 rounded-t'
                            style={{ objectPosition: "center" }}
                        />
                    </div>
                    <div className="proj-desc px-10 py-5 bg-white z-20 relative" style={{ zIndex: 20 }}>
                        <h2 className="text-[25px] sm:text-[30px] font-bold">Sustainability Site</h2>
                        <p className="text-[17px]  sm:text-[20px] font-normal mb-5">Nile Villa offers sustainability solutions, guiding businesses to eco-friendly, responsible practices.</p>
                        <a target="_blank" href="https://nilevilla.co.uk" className="border-b-4 font-medium text-[21px] flex w-max items-center">
                            Visit Site
                            <RxArrowTopRight className="text-[30px] font-bold" />
                        </a>
                    </div>
                </li>
            </ul>

            <p className="sm:block hidden mt-[30px] text-[22px] text-justify px-2">
                Our web development service offers a tailored, agile approach to creating bespoke websites that meet your specific requirements. We focus on delivering high-quality, responsive designs that provide an exceptional user experience. Our team works closely with you to ensure that every aspect of your website aligns with your brand and business goals, resulting in a powerful online presence.
            </p>
        </main>
    );
}
