import React from "react";
import { TbSpeakerphone } from "react-icons/tb";
import { FaChartLine } from "react-icons/fa";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { RxArrowTopRight } from "react-icons/rx";

export default function Services() {
    return (
        <main id="Services" className="w-full bg-[#EDF0F9] h-max py-[50px] lg:px-[100px] px-[20px] flex flex-col">
            <div className="services-title flex flex-col w-full h-max justify-center items-center">
                <span className="md:text-[60px] text-[33px] font-bold text-center text-black w-max">Services We Provide</span>
                <span className="md:text-[32px] text-[18px] translate-y-[-10px] font-normal text-black w-max text-center mb-[20px]">
                    What We Specialise In
                </span>
            </div>

            {/* Flexbox layout for services */}
            <ul className="flex w-full flex-col lg:flex-row lg:gap-[0] gap-[20px] text-left place-content-between">
                <li className="lg:w-1/3 w-[95%] mx-[10px] h-[100%] bg-white shadow-xl rounded-[10px] relative px-10 py-10">
                    <div className="px-3 py-4 bg-[#EDF0F9] w-[70px] h-[70px] rounded-xl items-center flex">
                        <img src='https://netreach-image-bucket.s3.us-east-2.amazonaws.com/uploads/Icon3.png' alt='Software Development' className='h-[50px] object-cover z-10 rounded-t' />
                    </div>
                    <h2 className="text-[28px] font-bold py-8">Software Development</h2>
                    <ul className="text-[25px] font-medium">
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Database Design</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Cloud Deployment</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Booking Systems</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">E-commerce</li>
                    </ul>
                </li>

                <li className="lg:w-1/3 w-[95%] mx-[10px] h-[100%] bg-white shadow-xl rounded-[10px] relative px-10 py-10">
                    <div className="px-3 py-4 bg-[#EDF0F9] w-[70px] h-[70px] rounded-xl items-center flex">
                        <img src='https://netreach-image-bucket.s3.us-east-2.amazonaws.com/uploads/Icon1.png' alt='Digital Marketing' className='h-[50px] object-cover z-10 rounded-t' />
                    </div>
                    <h2 className="text-[28px] font-bold py-8">Digital Marketing</h2>
                    <ul className="text-[25px] font-medium">
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Brand Identity</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Graphics Design</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Video Editing</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Content Creation</li>
                    </ul>
                </li>

                <li className="lg:w-1/3 w-[95%] mx-[10px] h-[100%] bg-white shadow-xl rounded-[10px] relative px-10 py-10">
                    <div className="px-3 py-4 bg-[#EDF0F9] w-[70px] h-[70px] rounded-xl items-center flex">
                        <img src='https://netreach-image-bucket.s3.us-east-2.amazonaws.com/uploads/Icon2.png' alt='Artificial Intelligence' className='h-[50px] object-cover z-10 rounded-t' />
                    </div>
                    <h2 className="text-[28px] font-bold py-8">Artificial Intelligence</h2>
                    <ul className="text-[25px] font-medium">
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Research Projects</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Natural Language</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Computer Vision</li>
                        <li className="relative pl-7 text-black before:content-[''] before:absolute before:left-0 before:top-1/2 before:transform before:-translate-y-1/2 before:w-4 before:h-4 before:rounded-full before:bg-[#7390F9]">Consultation</li>
                    </ul>
                </li>
            </ul>
        </main>
    );
}
